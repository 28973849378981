.Notes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Note {
  margin: 30px;
  font-size: 30px;
}

.Note.single {
}

.Note.double {
}

.Note.in-key {
  font-weight: bold;
  font-size: 40px;
}

.Note.tonic {
  background-color: pink;
}
