.Information {
  position: fixed;
  top: 100px;
  right: 100px;
}

.Information .visible {
  display: visible;
}
.Information .hidden {
  display: none;
}
