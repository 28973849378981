.Chord {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.Chord-Note {
  margin: 5px;
  font-style: italic;
}

.Chord-Voice {
  margin-right: 20px;
}

.Chord-Numeral {
  font-size: 20px;
  font-weight: bold;
  font-family: serif;
  margin-right: 10px;
}

button.selected {
  background-color: green;
}
